// Modificaciones en el componente InvitadosTotal
import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Button,
  Typography,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  Input,
  MenuItem,
  TextField, // Agregando TextField para el QR
} from "@mui/material";
import QrScanner from "react-qr-scanner";
import { format } from "date-fns";
const baseUrl = process.env.REACT_APP_BASE_URL;

const InvitadosTotal = () => {
  const [invitados, setInvitados] = useState([]);
  const [users, setUsers] = useState([]);
  const [lotes, setLotes] = useState([]);
  const [entradas, setEntradas] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState({
    id: "",
    lote: "",
    action: "",
    type: "",
    token: "",
    invitacionToken: "",
  }); // Agregado el token
  const [isScanning, setIsScanning] = useState(false);

  useEffect(() => {
    fetchInvitados();
    fetchUsers();
    fetchLotes();
    fetchEntradas();
  }, []);

  const fetchInvitados = async () => {
    const response = await axiosInstance.get(`${baseUrl}/api/invitados/invitadosPortero/getAll`);
    setInvitados(response.data);
  };

  const fetchUsers = async () => {
    const response = await axiosInstance.get(`${baseUrl}/api/auth`);
    setUsers(response.data);
  };

  const fetchLotes = async () => {
    const response = await axiosInstance.get(`${baseUrl}/api/lotes`);
    setLotes(response.data);
  };

  const fetchEntradas = async () => {
    const response = await axiosInstance.get(`${baseUrl}/api/lotes/entradas/getAll`);
    setEntradas(response.data);
  };

  const handleOpenEntry = () => {
    setCurrentAction({ id: "", lote: "", action: "", token: "" }); // Reiniciar el token
    setOpen(true);
  };

  const handleCloseEntry = () => {
    setOpen(false);
    setIsScanning(false); // Resetear el estado de escaneo al cerrar
  };

  const handleSaveEntry = async () => {
    // Preparar los datos para enviar al backend
    const entryData = {
      id: currentAction.id,
      lote: currentAction.lote,
      action: currentAction.action, // La acción se define por el usuario
      token: currentAction.token,
      invitacionToken: currentAction.invitacionToken, // Incluyendo este campo
    };

    // Enviar la solicitud al backend para validar la entrada o salida
    await axiosInstance.post(`${baseUrl}/api/invitados/validateEntry`, entryData);
    fetchEntradas();
    handleCloseEntry();
  };

  //   const handleScan = (data) => {
  //     if (data) {
  //       console.log("DATAAA", data);
  // const
  //       // Aquí puedes ajustar cómo estás extrayendo el token y otros datos del QR
  //       setCurrentAction((prevState) => ({
  //         ...prevState,
  //         token: data.token,
  //         lote: data.lote,
  //         invitacionToken: data.invitacionToken, // Asegúrate de que este campo esté presente
  //       }));
  //       setIsScanning(false); // Detener el escaneo después de recibir los datos
  //     }
  //   };

  const handleScan = (data) => {
    if (data) {
      console.log("DATAAA", data);

      const decodedData = JSON.parse(data.text);
      console.log("DECODED", decodedData);

      setCurrentAction((prevState) => ({
        ...prevState,
        token: decodedData.token,
        lote: decodedData.lote,
        invitacionToken: decodedData.invitacionToken,
      }));
      setIsScanning(false); // Detener el escaneo después de recibir los datos
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Logic for displaying the correct rows per page
  const paginatedEntradas = entradas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ padding: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Historial de Acciones</Typography>
        <Button variant="contained" color="primary" onClick={handleOpenEntry}>
          Agregar Entrada/Salida
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Nombre
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                CI
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Lote
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Accion
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Fecha
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedEntradas.map((entrada) => (
              <TableRow key={entrada.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {entrada.nombre}
                </TableCell>
                <TableCell component="th" scope="row">
                  {entrada.cedula_identidad}
                </TableCell>
                <TableCell align="left">
                  Lote : {entrada.lote} Manzana : {entrada.manzana}
                </TableCell>
                <TableCell align="left">{entrada.action}</TableCell>
                <TableCell align="left">
                  {format(new Date(entrada.createdAt), "dd/MM/yyyy HH:mm:ss")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={entradas.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage="Mostrar"
        />
      </TableContainer>

      <Dialog maxWidth="md" fullWidth open={open} onClose={handleCloseEntry}>
        <DialogTitle>Agregar Entrada/Salida</DialogTitle>
        <DialogContent>
          {/* Campo para escanear el QR o introducir el token */}

          <FormControl fullWidth margin="dense">
            <InputLabel id="action-select-label">Seleccionar Acción</InputLabel>
            <Select
              labelId="action-select-label"
              value={currentAction.action}
              onChange={(e) => setCurrentAction({ ...currentAction, action: e.target.value })}
              input={<Input />}
            >
              <MenuItem value="Entrada">Entrada</MenuItem>
              <MenuItem value="Salida">Salida</MenuItem>
            </Select>
          </FormControl>

          {/* Componente para escanear QR */}
          {isScanning ? (
            <QrScanner
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: "100%" }}
            />
          ) : (
            <Button onClick={() => setIsScanning(true)} variant="contained" color="primary">
              Escanear QR
            </Button>
          )}

          {currentAction && (
            <div style={{ marginTop: "20px", color: "green" }}>
              <h4>{currentAction.lote ? "Los datos se escanearon correctamente" : ""}</h4>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEntry} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSaveEntry} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InvitadosTotal;
